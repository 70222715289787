import { graphql } from "gatsby";
import About from "../components/pages/about";
import React from "react";
import Seo from "../components/layout/seo";

const intro = (
  <>
    <h2 className="is-h3b text-is-blue">Chi Siamo</h2>
    <p className="py-6">
      International Saws, marchio di riferimento del settore a partire dal 2015
      ha una nuova compagine societaria per perseguire nuovi piani di sviluppo a
      livello nazionale ed internazionale.
      <br />
      <br />
      La nuova società fondata e guidata da Giorgio Lamolinara, ha la sede
      legale e operativa in Roseto degli Abruzzi (TE), in modo da garantire a
      livello logistico un servizio tempestivo per l’intera penisola.
    </p>
    <h2 className="is-h3b text-is-blue mt-6">Cosa facciamo</h2>
    <p className="py-6">
      Una continua ricerca nei trattamenti superficiali, per una lunga durata
      anche in condizioni estreme di utilizzo delle lame e degli utensili.
      <br />
      <br />
      Riduzione dei rumori per un maggior comfort nell’utilizzo prolungato.
      <br />
      <br />
      Sviluppo di nuove soluzioni tecnologiche per soddisfare i bisogni di un
      mercato in continua evoluzione.
    </p>
  </>
);

const ChiSiamo = ({ data, location }) => (
  <>
    <Seo
      title="Chi Siamo"
      desc="Scopri Frese Elicoidali Z3 nella sezione Frese di International Saws. Lunga durata anche in condizioni estreme di utilizzo. Contattaci oggi!"
    />
    <About data={data} intro={intro} location={location} />
  </>
);

export const query = graphql`
  query aboutQuery {
    About1: file(relativePath: { eq: "about1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    About2: file(relativePath: { eq: "about2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default ChiSiamo;
